import "./App.css";

import { Route, Routes } from "react-router-dom";

import Protected from "./routes/Protected";
import ScrollToTop from "./utils/scrollToTop";

import Layout from "./pages/Layout";
import DashboardPage from "./pages/DashboardPage";
import CustomerPage from "./pages/CustomerPage";
import OrderCreationPage from "./pages/OrderCreationPage";
import OrderRecordPage from "./pages/OrderRecordPage";
import OrderDetailPage from "./pages/OrderDetailPage";
import OrderDateRecordPage from "./pages/OrderDateRecordPage";
import OrderManagePage from "./pages/OrderManagePage";
import SystemPage from "./pages/SystemPage";
import LoginPage from "./pages/LoginPage";
import ThermalPrinter from "./epos/Printer";
import DataOverviewPage from "./pages/DataOverviewPage";

import NotFoundPage from "./pages/NotFoundPage";
import NoAuthPage from "./pages/NoAuthPage";

import NavigateLayout from "./pages/NavigateLayout";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      {/* <ThermalPrinter /> */}
      <Routes>
        <Route path="/" element={<NavigateLayout />} />
        <Route path={`${process.env.REACT_APP_PATH}`} element={<LoginPage />} />
        <Route
          path={`${process.env.REACT_APP_PATH}/dashboard`}
          element={
            <Protected>
              <Layout />
            </Protected>
          }
        >
          <Route
            index
            element={
              <Protected>
                <DashboardPage />
              </Protected>
            }
          />
          <Route
            path="customer"
            element={
              <Protected>
                <CustomerPage />
              </Protected>
            }
          />
          <Route
            path="orderCreation"
            element={
              <Protected>
                <OrderCreationPage />
              </Protected>
            }
          />
          <Route
            path="orderRecord"
            element={
              <Protected>
                <OrderRecordPage />
              </Protected>
            }
          />
          <Route
            path="orderRecord/:orderDate"
            element={
              <Protected>
                <OrderDateRecordPage />
              </Protected>
            }
          />
          <Route
            path="orderRecord/order/:orderId"
            element={
              <Protected>
                <OrderDetailPage />
              </Protected>
            }
          />
          <Route
            path="orderManage"
            element={
              <Protected>
                <OrderManagePage />
              </Protected>
            }
          />
          <Route
            path="system"
            element={
              <Protected>
                <SystemPage />
              </Protected>
            }
          />
          <Route
            path="dataOverview"
            element={
              <Protected>
                <DataOverviewPage />
              </Protected>
            }
          />
          <Route path="thermalprinter" element={<ThermalPrinter />} />
        </Route>
        <Route path="redirect/fb" element={<NavigateLayout />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="NotFound" element={<NotFoundPage />} />
        <Route path="NoAuth" element={<NoAuthPage />} />
      </Routes>
    </div>
  );
}

export default App;
