import { styled, css } from "styled-components";
import { useState, useEffect } from "react";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import Calendar from "../../../global/Calendar";
import Button from "../../../global/Button";
import SubButton from "../../../global/SubButton";
import moment from "moment";
import { useGetStringQuantityByDateRangeMutation } from "../../../services/stringingApi";
import { useSelector } from "react-redux";
import { selectDropDown } from "../../../slices/systemSlice";
import Loading from "../../components/Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCircleExclamation,
  faArrowsRotate,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

import DateChart from "./DateChart";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 0px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  overflow-x: auto;
  font-size: clamp(14px, 0.833vw, 16px);
  flex: 1;
`;

const TableWrapper = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeaderTr = styled.tr``;

const TableHeader = styled.thead`
  /* display: flex; */
  /* width: 100vw; */
  position: sticky;
  top: 0;
  z-index: 1;
`;

const TableHeaderItem = styled.th`
  padding: 15px;

  white-space: nowrap;
  /* color: #d6d6d6; */
  background: #1c1c1c;
  color: #fafafa;

  ${RWD_SM(css`
    padding: 15px 10px;
  `)};
`;

const TableContainer = styled.tbody`
  /* padding-top: 20px; */
  /* overflow-x: scroll; */
  /* width: 100vw; */
`;

const ParamContainer = styled.tr`
  /* border-bottom: 1px solid #f3f4f6; */

  &:nth-child(even) {
    background-color: #fcfcfc;
  }

  /* &:hover {
    background-color: #fcfcfc;
  } */
`;

const ParamItem = styled.td`
  /* padding: 0 20px; */
  padding: 20px 15px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  white-space: nowrap;

  ${RWD_SM(css`
    padding: 15px 10px;
  `)};
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 50%;

  ${RWD_LG(css`
    grid-gap: 15px;
    width: 70%;
    /* grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); */
  `)};
`;

const SearchButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30%;
  margin-left: auto;

  button:first-child {
    margin-right: 15px;
  }
`;

const TableTitle = styled.div`
  font-size: clamp(16px, 1.041667vw, 20px);
  text-align: left;
  padding: 30px 0 20px 0;
`;

const CountWrapper = styled.div`
  padding: 40px 0;
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  flex: 1;
  /* color: #ccc; */
`;

const NoDataIcon = styled.div`
  font-size: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const NoDataText = styled.div`
  padding: 40px 0;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 400px;
`;

const ResetIcon = styled.div`
  cursor: pointer;
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
`;

const SortIcon = styled.div`
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
  transform: ${({ $sortOrder }) =>
    $sortOrder === "asc" ? "scaleY(1)" : "scaleY(-1)"};
`;

const DateTab = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [chartData, setChartData] = useState([]);
  const stringData = useSelector(selectDropDown)?.String;
  const [sortOrder, setSortOrder] = useState("asc");

  const header = [
    {
      id: 1,
      name: "編號",
      value: "ID",
      width: "10",
    },
    {
      id: 2,
      name: "線種名稱",
      value: "StringID",
      width: "60",
    },
    {
      id: 3,
      name: "數量",
      value: "Quantity",
      width: "30",
    },
  ];

  const handleClear = () => {
    setStartDate("");
    setEndDate("");

    const data = {
      StartDate: null,
      EndDate: null,
    };
    _getStringingQuantityByDateRange(data);
  };

  const [
    _getStringingQuantityByDateRange,
    { data, isError, error, isSuccess, isLoading },
  ] = useGetStringQuantityByDateRangeMutation();

  const handleSearch = () => {
    const data = {
      StartDate: moment(startDate).format("YYYY-MM-DD"),
      EndDate: moment(endDate).format("YYYY-MM-DD"),
    };
    _getStringingQuantityByDateRange(data);
  };

  useEffect(() => {
    if (isSuccess) {
      const sorted = [...(data?.ListGetSringQuantityByDateRange || [])].sort(
        (a, b) => b.Quantity - a.Quantity
      );
      setDataList(sorted);
      setChartData(
        data?.ListGetSringQuantityByDateRange?.map((item) => ({
          name: stringData?.filter(
            (string) => string.StringID == item.StringID
          )?.[0]?.StringName,
          quantity: item.Quantity,
        }))
      );
    }

    if (isError) {
    }
  }, [data, isError]);

  useEffect(() => {
    const data = {
      StartDate: null,
      EndDate: null,
    };
    _getStringingQuantityByDateRange(data);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      handleSearch();
    }
  }, [startDate, endDate]);

  const handleSort = () => {
    const sorted = [...dataList].sort((a, b) => {
      return sortOrder === "asc"
        ? a.Quantity - b.Quantity
        : b.Quantity - a.Quantity;
    });

    setDataList(sorted);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Container>
        {/* <SearchWrapper>
          <SearchContainer>
            <Calendar
              title={"開始日期"}
              selectDate={startDate}
              onChangeFun={setStartDate}
            />
            <Calendar
              title={"結束日期"}
              selectDate={endDate}
              onChangeFun={setEndDate}
            />
          </SearchContainer>
        </SearchWrapper> */}

        {/* <SearchButtonWrapper>
          <SubButton text={"清除"} onClickFun={handleClear} />
          <Button text={"查詢"} onClickFun={handleSearch} />
        </SearchButtonWrapper> */}

        <ChartWrapper>
          <SearchWrapper>
            <SearchContainer>
              <Calendar
                selectDate={startDate}
                onChangeFun={setStartDate}
                label={false}
                placeholder={"開始日期"}
                maxDate={true}
              />
              <Calendar
                selectDate={endDate}
                onChangeFun={setEndDate}
                label={false}
                placeholder={"結束日期"}
                maxDate={true}
              />
            </SearchContainer>
            <ResetIcon onClick={handleClear}>
              <FontAwesomeIcon icon={faArrowsRotate} />
            </ResetIcon>
          </SearchWrapper>
          {dataList.length > 0 && (
            <ChartContainer>
              <DateChart chartData={chartData} />
            </ChartContainer>
          )}
        </ChartWrapper>

        <TableTitle>資料列表</TableTitle>
        {dataList.length > 0 ? (
          <TableWrapper>
            <TableHeader>
              <TableHeaderTr>
                {header.map((item) => (
                  <TableHeaderItem
                    key={item.id}
                    style={{
                      width: `${item.width}%`,
                    }}
                  >
                    {item.value === "Quantity" ? (
                      <SortContainer>
                        {item.name}
                        <SortIcon $sortOrder={sortOrder} onClick={handleSort}>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </SortIcon>
                      </SortContainer>
                    ) : (
                      item.name
                    )}
                  </TableHeaderItem>
                ))}
              </TableHeaderTr>
            </TableHeader>
            <TableContainer>
              {dataList?.map((data, index) => (
                <ParamContainer key={index}>
                  {header.map((item) => (
                    <ParamItem key={item.id}>
                      {(() => {
                        switch (item.value) {
                          case "ID":
                            return index + 1;

                          case "StringID":
                            return stringData?.filter(
                              (string) => string.StringID == data[item.value]
                            )?.[0]?.StringName;

                          default:
                            return data[item.value];
                        }
                      })()}
                    </ParamItem>
                  ))}
                </ParamContainer>
              ))}
            </TableContainer>
          </TableWrapper>
        ) : (
          <NoData>
            <NoDataIcon>
              <FontAwesomeIcon icon={faFileCircleExclamation} />
            </NoDataIcon>
            <NoDataText>查無資料</NoDataText>
          </NoData>
        )}
      </Container>
      <CountWrapper>{`共 ${dataList.length} 筆`}</CountWrapper>
    </Wrapper>
  );
};

export default DateTab;
