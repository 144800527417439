import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useGetFunctionListMutation } from "../../services/authApi";

import { getAuthFun } from "../../utils";

import Logo from "../../images/logo.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faGear,
  faAddressBook,
  faTableList,
  faArrowRightFromBracket,
  faMagnifyingGlass,
  faFileCirclePlus,
  faFileShield,
  faPrint,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";

import Loading from "./Loading";

const Wrapper = styled.div`
  width: 200px;
  height: 100vh;
  background: #1e2022;
  position: fixed;
  top: 0;

  ${RWD_LG(css`
    top: initial;
    bottom: 0;
    height: 120px;
    width: 100%;
    z-index: 5;
  `)};

  ${RWD_SM(css`
    top: initial;
    bottom: 0;
    height: 80px;
    width: 100%;
    z-index: 5;
  `)};
`;

const Container = styled.div`
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${RWD_LG(css`
    flex-direction: row;
    padding: 0;
  `)};
`;

const LogoWrapper = styled.div`
  padding-top: 40px;

  ${RWD_LG(css`
    display: none;
  `)};
`;

const LogoContainer = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    object-position: center;
  }
`;

const ListWrapper = styled.div`
  padding-top: 20px;
  color: #fcfcfc;
  width: 100%;

  ${RWD_LG(css`
    padding-top: 0;
    display: flex;
    justify-content: space-evenly;
  `)};
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 0;

  ${RWD_LG(css`
    width: 100%;
    border-right: 1px solid #fcfcfc;
    padding: 0;

    &:last-child {
      border-right: 1px solid transparent;
    }
  `)};
  /* padding-bottom: 20px; */
`;

const ListTitle = styled.div`
  padding: 10px 0;
  flex: 1;
  text-align: left;
  color: ${({ $active }) => ($active ? "#fcfcfc" : "rgba(255, 255, 255, 0.5)")};

  ${RWD_SM(css`
    font-size: 13px;
    padding: 5px 0;
  `)};
`;

const ListTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
`;

const ListIcon = styled.div`
  font-size: clamp(30px, 1.875vw, 40px);
  color: ${({ $active }) => ($active ? "#fcfcfc" : "rgba(255, 255, 255, 0.5)")};

  ${RWD_SM(css`
    font-size: 23px;
  `)};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  width: calc(100% - 60px);
`;

const ListItem = styled.li`
  padding: 10px 0;
  text-align: left;
  list-style: none;
  width: 100%;
  cursor: pointer;
  /* &:hover {
    background-color: #fcfcfc;
    color: #16161a;
  } */
`;

const Logout = styled.div`
  margin-top: auto;

  color: #fcfcfc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 40px;
  cursor: pointer;

  ${RWD_LG(css`
    display: none;
  `)};
`;

const LogoutIcon = styled.div`
  width: 40px;
`;

const Menu = ({ handleAgreement }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuList, setMenuList] = useState([]);
  const authData = getAuthFun();

  console.log(authData);

  const [_getFunList, { isSuccess, data, isError, error, isLoading }] =
    useGetFunctionListMutation();

  useEffect(() => {
    _getFunList();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setMenuList([
        ...data.Functions.map((data) => {
          switch (data.ID) {
            case 1:
              return {
                ...data,
                icon: <FontAwesomeIcon icon={faTableList} />,
                link: "orderRecord",
              };
            case 2:
              return {
                ...data,
                icon: <FontAwesomeIcon icon={faFileCirclePlus} />,
                link: "orderCreation",
              };
            case 3:
              return {
                ...data,
                icon: <FontAwesomeIcon icon={faFileShield} />,
                link: "orderManage",
              };
            case 4:
              return {
                ...data,
                icon: <FontAwesomeIcon icon={faGear} />,
                link: "system",
              };

            default:
              break;
          }
        }),
        {
          ID: 5,
          NameZh: "連線設定",
          icon: <FontAwesomeIcon icon={faPrint} />,
          link: "thermalprinter",
        },
        {
          ID: 6,
          NameZh: "資料總覽",
          icon: <FontAwesomeIcon icon={faDatabase} />,
          link: "dataoverview",
        },
      ]);
    }
  }, [data, isError]);

  // const [menuList, setMenuList] = useState([
  //   {
  //     id: 1,
  //     name: "訂單紀錄",
  //     icon: <FontAwesomeIcon icon={faTableList} />,
  //     link: "/dashboard/orderRecord",
  //     isOpen: true,
  //     subList: [
  //       {
  //         id: 1,
  //         name: "訂單查詢",
  //         link: "/dashboard/orderRecord",
  //       },
  //       {
  //         id: 2,
  //         name: "訂單建立",
  //         link: "/dashboard/orderCreation",
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: "訂單建立",
  //     icon: <FontAwesomeIcon icon={faFileCirclePlus} />,
  //     link: "/dashboard/orderCreation",
  //     isOpen: false,
  //     subList: [
  //       {
  //         id: 1,
  //         name: "客戶查詢",
  //         link: "",
  //       },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     name: "訂單管理",
  //     icon: <FontAwesomeIcon icon={faFileShield} />,
  //     link: "/dashboard/orderManage",
  //     isOpen: false,
  //     subList: [
  //       {
  //         id: 1,
  //         name: "客戶查詢",
  //         link: "",
  //       },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     name: "系統維護",
  //     icon: <FontAwesomeIcon icon={faGear} />,
  //     link: "/dashboard/system",
  //     isOpen: false,
  //     subList: [
  //       {
  //         id: 1,
  //         name: "線種設定",
  //         link: "",
  //       },
  //       {
  //         id: 2,
  //         name: "顏色維護",
  //         link: "",
  //       },
  //       {
  //         id: 3,
  //         name: "孔丁維護",
  //         link: "",
  //       },
  //     ],
  //   },
  //   {
  //     id: 5,
  //     name: "機器連線",
  //     icon: <FontAwesomeIcon icon={faPrint} />,
  //     link: "/dashboard/thermalprinter",
  //     isOpen: false,
  //     subList: [
  //       {
  //         id: 1,
  //         name: "線種設定",
  //         link: "",
  //       },
  //       {
  //         id: 2,
  //         name: "顏色維護",
  //         link: "",
  //       },
  //       {
  //         id: 3,
  //         name: "孔丁維護",
  //         link: "",
  //       },
  //     ],
  //   },
  // ]);

  const handleLink = (link) => {
    // 如果是點擊訂單建立 打開同意聲明燈箱
    if (link === "orderCreation") {
      handleAgreement();
    }
    navigate(link);
  };

  // useEffect(() => {
  //   setMenuList(
  //     menuList.map((data) => {
  //       return {
  //         ...data,
  //         isOpen:
  //           data.subList.filter(
  //             (url) =>
  //               location.pathname.split("/").indexOf(url.link.split("/")[2]) >
  //               -1
  //           ).length > 0,
  //       };
  //     })
  //   );
  // }, [location]);

  const handleMenuStatus = (id) => {
    setMenuList(
      menuList.filter((data) => {
        if (data.id === id && !data.isOpen) {
          data.isOpen = true;
        } else {
          data.isOpen = false;
        }
        return data;
      })
    );
  };

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Container>
        <LogoWrapper>
          <LogoContainer>
            <img src={Logo} />
          </LogoContainer>
        </LogoWrapper>

        <ListWrapper>
          {menuList.map((data) => (
            <Fragment key={data.ID}>
              {data.ID !== 5 && data.ID !== 6 ? (
                getAuthFun()?.filter((auth) => auth.FunctionId === data.ID)?.[0]
                  ?.AuthSeq == 1 && (
                  <ListContainer>
                    <ListTitleWrapper onClick={() => handleLink(data.link)}>
                      <ListIcon $active={location.pathname.includes(data.link)}>
                        {data.icon}
                      </ListIcon>
                      <ListTitle
                        $active={location.pathname.includes(data.link)}
                      >
                        {data.NameZh}
                      </ListTitle>
                    </ListTitleWrapper>
                    {/* {data.isOpen && (
                  <List>
                    {data?.subList?.map((list) => (
                      <ListItem onClick={() => handleLink(list.link)}>
                        {list.name}
                      </ListItem>
                    ))}
                  </List>
                )} */}
                  </ListContainer>
                )
              ) : data.ID === 6 ? (
                getAuthFun()?.some(
                  (auth) => auth.FunctionId === 3 && auth.AuthSeq === 1
                ) &&
                getAuthFun()?.some(
                  (auth) => auth.FunctionId === 4 && auth.AuthSeq === 1
                ) && (
                  <ListContainer key={data.ID}>
                    <ListTitleWrapper onClick={() => handleLink(data.link)}>
                      <ListIcon $active={location.pathname.includes(data.link)}>
                        {data.icon}
                      </ListIcon>
                      <ListTitle
                        $active={location.pathname.includes(data.link)}
                      >
                        {data.NameZh}
                      </ListTitle>
                    </ListTitleWrapper>
                  </ListContainer>
                )
              ) : (
                <ListContainer key={data.ID}>
                  <ListTitleWrapper onClick={() => handleLink(data.link)}>
                    <ListIcon $active={location.pathname.includes(data.link)}>
                      {data.icon}
                    </ListIcon>
                    <ListTitle $active={location.pathname.includes(data.link)}>
                      {data.NameZh}
                    </ListTitle>
                  </ListTitleWrapper>
                </ListContainer>
              )}
            </Fragment>
          ))}
        </ListWrapper>
      </Container>
    </Wrapper>
  );
};

export default Menu;
