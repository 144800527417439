import { styled, css } from "styled-components";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectDropDown } from "../slices/systemSlice";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  /* border: 1px solid blue; */
`;

const Container = styled.div`
  display: grid;
  align-items: center;
  position: relative;
  grid-template-columns: ${({ $isLabel }) => ($isLabel ? "30% 70%" : "100%")};
`;

const LabelEl = styled.label`
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: clamp(15px, 0.9375vw, 18px);
  color: ${({ $required, $invalid }) =>
    $required ? ($invalid ? "#EF233C" : "#F77F00") : "#003060"};
`;

const InputEl = styled.input`
  padding: 10px 20px;
  background: #fafafa;
  border: none;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
  position: relative;
  width: 100%;
  border: ${({ $invalid }) =>
    $invalid ? "2px solid #EF233C" : "1px solid transparent"};
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
`;

const DropdownStyle = styled.div`
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;

  /* overflow: hidden; */

  max-height: 20vmax;
  z-index: 5;
  width: 100%;

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      max-height: 40px;
      visibility: hidden;
    `}
`;

const Item = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 40px);
`;

const ItemName = styled.div``;

const ItemPrice = styled.div``;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  /* font-size: 0.9375vw; */

  /* ${(props) =>
    props.active &&
    css`
      color: #009a63;
      font-weight: 500;
    `} */
  &:hover,
  :focus,
  :focus:hover {
    background-color: #16161a;
    color: #fafafa;
    outline: none;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const RequiredIcon = styled.div`
  font-size: 5px;
  padding-left: 4px;
`;

const SearchInput = ({
  title,
  placeholder,
  onChangeFun,
  disabled,
  value,
  required,
  invalid,
  price = true,
  label = true,
}) => {
  const string = useSelector(selectDropDown)?.String;

  const [filterData, setFilterData] = useState([]);

  const [currentValue, setCurrentValue] = useState("");
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    setFilterData(string?.filter((data) => data.Enabled));
  }, [string]);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    } else {
      ref.current.value = "";
      setCurrentValue("");
    }
  }, [value]);

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = (value) => {
    setCurrentValue(value);
  };
  const handleChange = (value, name) => {
    handleValueChange(value);
    onChangeFun(value);
    handleClose();
  };

  const handleFilter = (value) => {
    setCurrentValue("");

    if (value !== "") {
      const reg = new RegExp(value, "i");

      const allFilter = string.filter((item) =>
        item.StringOfficialName.match(reg)
      );

      const set = new Set();

      const result = allFilter.filter((item) =>
        !set.has(item.StringOfficialName)
          ? set.add(item.StringOfficialName)
          : false
      );
      setFilterData(result);
      setOpen(true);
    } else {
      setFilterData(string);
      onChangeFun("");
    }
  };

  const handleFocus = (e) => {
    setFocused(true);
  };
  const handleBlur = (e) => {
    setFocused(false);
  };

  return (
    <Wrapper>
      <Container $isLabel={label}>
        {label && (
          <LabelEl
            $required={required}
            $invalid={invalid && !focused && !currentValue}
          >
            {title}
            {required && (
              <RequiredIcon>
                <FontAwesomeIcon icon={faStarOfLife} />
              </RequiredIcon>
            )}
          </LabelEl>
        )}

        <SelectContainer>
          <InputWrapper
            onClick={handleOpen}
            $invalid={invalid && !focused && !currentValue}
            $required={required}
          >
            {string && (
              <InputEl
                // defaultValue={defaultValue}
                ref={ref}
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => handleFilter(e.target.value)}
                disabled={disabled}
                value={
                  string.filter((data) => data.StringID == currentValue)[0]
                    ?.StringOfficialName
                }
              />
            )}

            <IconWrapper>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </IconWrapper>
          </InputWrapper>
          <DropdownStyle $isVisible={open}>
            {filterData &&
              filterData.map((data) => (
                <DropdownItem
                  value={data.StringID}
                  key={data.StringID}
                  onClick={() =>
                    handleChange(data.StringID, data.StringOfficialName)
                  }
                >
                  <Item>
                    <ItemName>{data?.StringOfficialName}</ItemName>
                    {price && <ItemPrice>$ {data.SellingPrice}</ItemPrice>}
                  </Item>
                </DropdownItem>
              ))}
          </DropdownStyle>
        </SelectContainer>
      </Container>
    </Wrapper>
  );
};

export default SearchInput;
