import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { forwardRef, useRef, useState, useEffect } from "react";
import { useGetStringQuantityByDateRangeMutation } from "../../services/stringingApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import {
  faDollarSign,
  faCalendarDays,
  faAngleDown,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";

import Loading from "../components/Loading";

import StringTab from "./Tab/StringTab";
import DateTab from "./Tab/DateTab";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { getAuthFun } from "../../utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  height: 100%;

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 150px);
  `)};

  ${RWD_LG(css`
    min-height: calc(100vh - 60px - 110px);
  `)};
`;

const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding-bottom: 50px;
  font-size: clamp(14px, 0.833vw, 16px);

  ${RWD_LG(css`
    padding-bottom: 150px;
  `)};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;

  ${RWD_SM(css`
    padding: 0 0 20px 0;
  `)};
`;

const Title = styled.div`
  font-size: clamp(20px, 1.25vw, 25px);
  font-weight: bold;
`;

const YearWrapper = styled.div`
  position: relative;
  display: grid;
  transition: 400ms grid-template-rows ease;
  grid-template-rows: ${({ $isCollapsed }) => ($isCollapsed ? "0fr" : "1fr")};
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 30px; */

  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px;
`;

const YearContainer = styled.div`
  gap: 40px;
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px 0 10px 0;

  ${RWD_LG(css`
    grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
  `)};
`;

const YearBox = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;

  border-right: 1px solid rgba(0, 0, 0, 0.05);

  &:last-child {
    border-right: 0px;
  }
`;

const YearBoxIconContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  font-size: clamp(40px, 2.6vw, 50px);
`;

const YearBoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c1c1c;
`;

const YearBoxContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const YearBoxName = styled.div`
  text-align: left;
  padding-bottom: 5px;
`;

const YearCountBox = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 40px;
  font-family: monospace;
  justify-content: flex-start;
  align-items: baseline;

  span {
    font-size: 14px;
    font-weight: normal;
    padding-left: 30px;
  }
`;

const SlideWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -25px;
  transform: ${({ $isCollapsed }) =>
    $isCollapsed ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.3s ease-in-out;

  padding: 0 5px;
`;

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  border-bottom: 1px solid #f5f5f5;

  ${RWD_LG(css`
    padding-top: 20px;
  `)};
`;

const TabContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 10px;
  overflow: auto;
`;

const Tab = styled.div`
  padding: 10px 30px;
  border: 1px solid;
  /* border-bottom: 0px; */
  /* margin-right: 10px; */
  /* border-radius: 40px; */

  white-space: nowrap;
  color: ${({ $active }) => ($active ? "#fafafa" : "#1c1c1c")};
  border-color: ${({ $active }) => ($active ? " #16161a" : "#FAFAFA")};
  background-color: ${({ $active }) => ($active ? " #16161a" : "#FAFAFA")};
  cursor: pointer;
  font-size: clamp(14px, 0.833vw, 16px);
  border-bottom: 0px;

  ${RWD_LG(css`
    padding: 10px 20px;
  `)};

  ${RWD_SM(css`
    padding: 5px 15px;
  `)};

  /* background: #fafafa; */
`;

const TabText = styled.div``;

const TabContentContainer = styled.div`
  /* border: 1px solid #16161a; */
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

const SearchWrapper = styled.div``;

const SearchContainer = styled.div`
  padding: 40px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
  grid-gap: 20px 5vw;

  ${RWD_LG(css`
    padding: 0 0 20px 0;
    grid-gap: 15px 0;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  `)};
`;

const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 15px;
  right: 15px;
`;

const CalendarTitle = styled.div`
  font-size: clamp(16px, 1.041667vw, 20px);
  text-align: left;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
  border: ${({ $invalid }) =>
    $invalid ? "2px solid #EF233C" : "1px solid transparent"};
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Content = styled.div`
  padding: 10px 20px;
  color: #16161a;
  width: 100%;
  text-align: left;
  font-size: 14px;
`;

const DataOverviewPage = () => {
  const navigate = useNavigate();
  const authData = getAuthFun();

  // 權限判斷
  // FunctionId 4 系統維護 FunctionId 3 訂單管理 有權限才顯示
  const [haveAuth, setHaveAuth] = useState(
    authData?.some((auth) => auth.FunctionId === 3 && auth.AuthSeq === 1) &&
      authData?.some((auth) => auth.FunctionId === 4 && auth.AuthSeq === 1)
  );

  useEffect(() => {
    if (!haveAuth) {
      navigate("/NoAuth");
    }
  }, []);

  const [totalCount, setTotalCount] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectYear, setSelectYear] = useState(
    new Date(moment().subtract(1, "year").format("YYYY"))
  );
  const dateInputRef = useRef(null);

  const [tabList, setTabList] = useState([
    {
      id: 1,
      name: "日期",
    },
    {
      id: 2,
      name: "線種",
    },
  ]);

  const [
    _getStringingQuantityByDateRange,
    { data, isError, error, isSuccess, isLoading },
  ] = useGetStringQuantityByDateRangeMutation();

  useEffect(() => {
    const lastYearStartDate = moment()
      .subtract(1, "year")
      .startOf("year")
      .format("YYYY-MM-DD");
    const lastYearEndDate = moment()
      .subtract(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD");

    const data = {
      StartDate: lastYearStartDate,
      EndDate: lastYearEndDate,
    };

    _getStringingQuantityByDateRange(data);
  }, []);

  const handleSlide = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (isSuccess) {
      setTotalCount(data?.OrderTotalCount);
    }

    if (isError) {
      console.log(error?.data?.Message);
    }
  }, [data, isError]);

  useEffect(() => {
    if (selectYear) {
      const data = {
        StartDate: moment(selectYear).startOf("year").format("YYYY-MM-DD"),
        EndDate: moment(selectYear).endOf("year").format("YYYY-MM-DD"),
      };

      _getStringingQuantityByDateRange(data);
    }
  }, [selectYear]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <InputWrapper onClick={onClick} ref={ref}>
        <Content>{value}</Content>
        <IconWrapper>
          <FontAwesomeIcon icon={faCalendar} />
        </IconWrapper>
      </InputWrapper>
    );
  });

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Container>
        <TitleWrapper>
          <Title>資料總覽</Title>
        </TitleWrapper>
        <YearWrapper $isCollapsed={open}>
          <CalendarWrapper>
            {/* <CalendarTitle>年度數據</CalendarTitle> */}
            <DatePicker
              showYearPicker
              dateFormat="yyyy"
              yearItemNumber={6}
              selected={selectYear}
              onChange={(date) => setSelectYear(date)}
              maxDate={new Date()}
              minDate={new Date("2023-01-01")}
              customInput={<CustomInput ref={dateInputRef} />}
            />
          </CalendarWrapper>

          <YearContainer>
            <YearBox>
              <YearBoxIconContainer>
                <YearBoxIcon>
                  <FontAwesomeIcon icon={faCalendarDays} />
                </YearBoxIcon>
              </YearBoxIconContainer>

              <YearBoxContainer>
                <YearBoxName>年度</YearBoxName>
                <YearCountBox>
                  {moment(selectYear).year()}
                  <span>年</span>
                </YearCountBox>
              </YearBoxContainer>
            </YearBox>
            <YearBox>
              <YearBoxIconContainer>
                <YearBoxIcon>
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                </YearBoxIcon>
              </YearBoxIconContainer>
              <YearBoxContainer>
                <YearBoxName>訂單總數</YearBoxName>
                <YearCountBox>
                  {totalCount}
                  <span>筆</span>
                </YearCountBox>
              </YearBoxContainer>
            </YearBox>
          </YearContainer>

          {/* <SlideWrapper onClick={handleSlide} $isCollapsed={open}>
            <FontAwesomeIcon icon={faAngleDown} />
          </SlideWrapper> */}
        </YearWrapper>
        <TabWrapper>
          <TabContainer>
            {tabList.map((data) => (
              <Tab
                key={data.id}
                $active={activeTab === data.id}
                onClick={() => setActiveTab(data.id)}
              >
                <TabText>{data.name}</TabText>
              </Tab>
            ))}
          </TabContainer>
        </TabWrapper>
        <TabContentContainer>
          {activeTab == 1 && <DateTab />}
          {activeTab == 2 && <StringTab />}
        </TabContentContainer>

        {/* <SearchWrapper>
          <SearchContainer>
            <Calendar
              title={"開始日期"}
              selectDate={startDate}
              onChangeFun={setStartDate}
            />
            <Calendar
              title={"結束日期"}
              selectDate={endDate}
              onChangeFun={setEndDate}
            />
          </SearchContainer>
        </SearchWrapper> */}
      </Container>
    </Wrapper>
  );
};

export default DataOverviewPage;
