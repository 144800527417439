import { styled, css } from "styled-components";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const TooltipContainer = styled.div`
  background: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  border: 1px solid #1c1c1c;
`;

const TooltipList = styled.div``;

const TooltipItem = styled.div``;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <TooltipList>
          <TooltipItem>{`線種 : ${label}`}</TooltipItem>
        </TooltipList>
        <TooltipList>
          <TooltipItem>{`數量 : ${payload[0].value}`}</TooltipItem>
        </TooltipList>
      </TooltipContainer>
    );
  }

  return null;
};

const CustomizedAxisTick = ({
  x,
  y,
  stroke,
  payload,
  width,
  height,
  fontSize,
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={15}
        textAnchor="end"
        fill="#666"
        transform="rotate(-25)"
        fontSize={12}
      >
        {payload.value}
      </text>
    </g>
  );
};

const Chart = ({ chartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 0,
          left: -10,
          bottom: 40,
        }}
      >
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
        {/* <YAxis /> */}
        <Tooltip
          content={<CustomTooltip />}
          cursor={{
            fill: "#fafafa",
          }}
        />
        {/* <Legend /> */}
        <Bar
          dataKey="quantity"
          fill="#1c1c1c"
          label={{ position: "top", fontSize: 12 }}
          hoverFill="red"

          //   activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
