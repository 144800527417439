import { styled, css } from "styled-components";
import { useEffect, useState } from "react";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import {
  useUpdateStringMutation,
  useCreateStringMutation,
} from "../../services/systemApi";

import { useSelector } from "react-redux";
import { selectDropDown } from "../../slices/systemSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

import Input from "../../global/Input";
import Textarea from "../../global/Textarea";
import Button from "../../global/Button";
import Select from "../../global/Select";
import Checkbox from "../../global/Checkbox";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StringCostModalLayout from "./StringCostModalLayout";
import MultipleCheckbox from "../../global/MultipleCheckbox";

import { filteredObject } from "../../utils";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 80vw;
  height: 80%;
  max-width: 850px;
  position: relative;
  font-size: clamp(14px, 0.833vw, 16px);

  ${RWD_LG(css`
    padding: 20px;
  `)};

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;

  ${RWD_SM(css`
    padding-right: 10px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 30px;

  ${RWD_SM(css`
    font-size: clamp(18px, 4.65vw, 20px);
    padding-bottom: 15px;
  `)};
`;

const CloseIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background: #16161a;
  color: #fcfcfc;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px 5vw;
`;

const ButtonWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  gap: 30px;
`;

const TableWrapper = styled.div`
  padding: 90px 0 50px 0;

  ${RWD_SM(css`
    padding: 20px 0;
  `)};
`;

const TableContainer = styled.div`
  overflow-x: auto;
  /* width: 100%; */
  /* width: 200px; */
`;

const TableEl = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background: #fafafa;
  color: #16161a;
  /* position: sticky;
  top: 0;
  z-index: 1; */
`;

const TableHeaderTr = styled.tr``;

const TableTr = styled.tr`
  padding: 20px 0;
`;

const TableTh = styled.th`
  /* width: calc(100% / 6); */
  padding: 10px;
  white-space: nowrap;
`;

const TableTd = styled.td`
  border: 1px solid #f3f4f6;
  padding: 10px;
  white-space: nowrap;
`;

const TbodyEl = styled.tbody``;

const AddPriceButtonWrapper = styled.div`
  /* position: absolute;
  top: -120%;
  right: 0; */
  width: 100px;
  margin-left: auto;
  padding-bottom: 10px;
`;

const ColourBoxWrapper = styled.div`
  padding-top: 40px;
`;

const StringModalLayout = ({
  modalType,
  modalData,
  setIsOpen,
  setModalType,
}) => {
  const colourData = useSelector(selectDropDown).List?.Colour;
  const MySwal = withReactContent(Swal);

  const [stringID, setStringID] = useState("");
  const [stringOfficialName, setStringOfficialName] = useState(""); // 官方名稱
  const [stringName, setStringName] = useState(""); // 線種暱稱
  const [manufacturer, setManufacturer] = useState(""); // 廠商
  const [gauge, setGauge] = useState(""); // 線徑
  const [specification, setSpecification] = useState(""); // 特色
  const [strokeFeel, setStrokeFeel] = useState(""); // 擊球感
  const [description, setDescription] = useState(""); // 描述
  const [enabled, setEnabled] = useState(true); // 供應狀況
  const [listColours, setListColours] = useState([]); // 顏色
  const [listStringPriceRecords, setListStringPriceRecords] = useState([]); // 價格

  const [costModalIsOpen, setCostModalIsOpen] = useState(false);
  const [costModalType, setCostModalType] = useState("");
  const [costModalData, setCostModalData] = useState([]);

  const isValid =
    stringOfficialName !== "" &&
    manufacturer !== "" &&
    listStringPriceRecords !== "" &&
    listStringPriceRecords?.filter((data) => data.Enabled == 1).length > 0;

  useEffect(() => {
    if (modalData.length > 0 && modalType !== "create") {
      setStringID(modalData[0]?.StringID);
      setStringOfficialName(modalData[0]?.StringOfficialName);
      setStringName(modalData[0]?.StringName);
      setManufacturer(modalData[0]?.ManufacturerID);
      setGauge(modalData[0]?.Gauge);
      setSpecification(modalData[0]?.Specification);
      setStrokeFeel(modalData[0]?.StrokeFeel);
      setDescription(modalData[0]?.Description);
      setEnabled(modalData[0]?.Enabled);
      setListColours(modalData[0]?.ListStringColours ?? []);
      setListStringPriceRecords(
        modalData[0]?.ListStringPrice?.map((data, index) => {
          return {
            ...data,
            id: index + 1,
          };
        })
      );
    }
  }, [modalData]);

  const [
    _update,
    {
      data: updateData,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      error: updateError,
    },
  ] = useUpdateStringMutation();

  const [
    _create,
    {
      data: createData,
      isError: createIsError,
      isSuccess: createIsSuccess,
      error: createError,
    },
  ] = useCreateStringMutation();

  useEffect(() => {
    if (createIsSuccess) {
      MySwal.fire({
        title: "建立成功",
        icon: "success",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          handleCloseFun();
          handleClear();
        } else {
          handleClear();
        }
      });
    }

    if (updateIsError) {
      MySwal.fire({
        title: "建立失敗",
        text: createError?.data?.Message,
        icon: "error",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      });
    }
  }, [createData, createIsError]);

  useEffect(() => {
    if (updateIsSuccess) {
      MySwal.fire({
        title: "更新成功",
        icon: "success",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsOpen(false);
          handleClear();
        } else {
          handleClear();
        }
      });
    }

    if (updateIsError) {
      MySwal.fire({
        title: "更新失敗",
        text: updateError?.data?.Message,
        icon: "error",
        confirmButtonText: "確認",
        confirmButtonColor: "#16161a",
        allowOutsideClick: false,
      });
    }
  }, [updateData, updateIsError]);

  const handleCloseFun = () => {
    setIsOpen(false);
    setModalType("");
  };

  const handleUpdate = () => {
    const data = {
      StringID: stringID,
      StringOfficialName: stringOfficialName,
      StringName: stringName,
      Manufacturer: manufacturer,
      Gauge: gauge,
      Specification: specification,
      StrokeFeel: strokeFeel,
      Description: description,
      Enabled: enabled,
      ListColours: listColours,
      ListStringPriceRecords: filteredObject(listStringPriceRecords),
    };

    // console.log(data);

    _update(data);
  };

  const handleCreate = () => {
    const data = {
      StringOfficialName: stringOfficialName,
      StringName: stringName,
      Manufacturer: manufacturer,
      Gauge: gauge,
      Specification: setDescription,
      StrokeFeel: strokeFeel,
      Description: description,
      ListColours: listColours,
      ListStringPriceRecords: listStringPriceRecords,
    };

    _create(data);
  };

  const handleClear = () => {
    setStringID("");
    setStringOfficialName("");
    setStringName("");
    setManufacturer("");
    setGauge("");
    setSpecification("");
    setStrokeFeel("");
    setDescription("");
    setEnabled(true);
    setListColours([]);
    setListStringPriceRecords([]);
  };

  const handleOpenCostEditModal = (id) => {
    setCostModalType("edit");
    setCostModalData(listStringPriceRecords?.filter((data) => data.id === id));
    setCostModalIsOpen(true);
  };

  const handleOpenModal = () => {
    setCostModalType("create");
    setCostModalIsOpen(true);
  };

  return (
    <Wrapper>
      <Container>
        <CloseIcon onClick={handleCloseFun}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseIcon>
        <Title>
          線種
          {modalType === "create" ? "建立" : "維護"}
        </Title>
        <ScrollContainer>
          <FormWrapper>
            <Input
              title={"官方名稱"}
              onChangeFun={setStringOfficialName}
              value={stringOfficialName}
            />
            <Input
              title={"線種暱稱"}
              onChangeFun={setStringName}
              value={stringName}
            />
            <Select
              title={"廠商"}
              group={"Manufacturer"}
              onChangeFun={setManufacturer}
              value={manufacturer}
            />

            <Checkbox
              title={"供應狀況"}
              onChangeFun={setEnabled}
              value={enabled}
            />
            <Input title={"線徑"} onChangeFun={setGauge} value={gauge} />
            <Input
              title={"特色"}
              onChangeFun={setSpecification}
              value={specification}
            />
            <Input
              title={"擊球感"}
              onChangeFun={setStrokeFeel}
              value={strokeFeel}
            />
          </FormWrapper>
          <Textarea
            title={"描述"}
            onChangeFun={setDescription}
            value={description}
          />
          <ColourBoxWrapper>
            <MultipleCheckbox
              colorListData={listColours}
              setColorListData={setListColours}
            />
          </ColourBoxWrapper>

          <TableWrapper>
            <AddPriceButtonWrapper>
              <Button text={"新增價格"} onClickFun={handleOpenModal} />
            </AddPriceButtonWrapper>
            <TableContainer>
              <TableEl>
                <TableHeader>
                  <TableHeaderTr>
                    <TableTh>起始日</TableTh>
                    <TableTh>穿線價</TableTh>
                    <TableTh>官方定價</TableTh>
                    <TableTh>售價(單買線)</TableTh>
                    <TableTh>成本</TableTh>
                    <TableTh>操作</TableTh>
                  </TableHeaderTr>
                </TableHeader>
                <TbodyEl>
                  {listStringPriceRecords?.map((data) => (
                    <TableTr key={data.id}>
                      <TableTd>{data.StartDate.split(" ")[0]}</TableTd>
                      <TableTd>{data.SellingPrice}</TableTd>
                      <TableTd>{data.MarkedPrice}</TableTd>
                      <TableTd>{data.SinglePurchasePrice}</TableTd>
                      <TableTd>{data.Cost}</TableTd>
                      <TableTd onClick={() => handleOpenCostEditModal(data.id)}>
                        <FontAwesomeIcon icon={faPencil} />
                      </TableTd>
                    </TableTr>
                  ))}
                </TbodyEl>
              </TableEl>
            </TableContainer>
          </TableWrapper>
          <ButtonWrapper>
            {modalType === "create" && (
              <Button text={"清除資料"} onClickFun={handleClear} />
            )}

            {modalType === "create" ? (
              <Button
                text={"建立"}
                onClickFun={handleCreate}
                disabled={!isValid}
              />
            ) : (
              <Button
                text={"更新"}
                onClickFun={handleUpdate}
                disabled={!isValid}
              />
            )}
          </ButtonWrapper>
        </ScrollContainer>
      </Container>
      {costModalIsOpen && (
        <StringCostModalLayout
          listStringPriceRecords={listStringPriceRecords}
          setListStringPriceRecords={setListStringPriceRecords}
          setCostModalIsOpen={setCostModalIsOpen}
          costModalType={costModalType}
          costModalData={costModalData}
        />
      )}
    </Wrapper>
  );
};

export default StringModalLayout;
