import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "./query/defaultBaseQuery";
import * as qs from "qs";

export const stringingApi = createApi({
  reducerPath: "stringingApi",
  baseQuery: baseQueryWithToken,
  endpoints: (builder) => ({
    getOrderByDate: builder.mutation({
      query: ({ ReceivedDateStart, ReceivedDateEnd }) => ({
        url: `Master/GetOrderByDate`,
        method: "POST",
        body: qs.stringify({
          ReceivedDateStart,
          ReceivedDateEnd,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
    getAdminOrderByDate: builder.mutation({
      query: ({
        ReceivedDateStart,
        ReceivedDateEnd,
        CustomerName,
        CustomerPhone,
      }) => ({
        url: `Master/GetAdminOrderByDate`,
        method: "POST",
        body: qs.stringify({
          ReceivedDateStart,
          ReceivedDateEnd,
          CustomerName,
          CustomerPhone,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
    getOrderOfTheDay: builder.mutation({
      query: ({ ReceivedDate, CustomerName, CustomerID }) => ({
        url: `Master/GetOrderOfTheDay`,
        method: "POST",
        body: qs.stringify({
          ReceivedDate,
          CustomerName,
          CustomerID,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
    getOrderDetail: builder.query({
      query: ({ OrderID, OrderNo }) => ({
        url: `Master/OrderDetail`,
        method: "POST",
        body: qs.stringify({
          OrderID,
          OrderNo,
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }),
    }),
    updateOrder: builder.mutation({
      query: ({
        OrderID,
        OrderNo,
        CustomerID,
        CustomerName,
        StringID,
        ColourID,
        ColourName,
        Lbs,
        Method,
        IsNew,
        Deposit,
        AmountPayable,
        PayStatus,
        ReceivedDate,
        DeliveryDate,
        Adjudicator,
        Cost,
        Remark,
        Enabled,
        ListOrderAddOn,
        ListCustomize,
      }) => ({
        url: `Master/OrderEdit`,
        method: "POST",
        body: JSON.stringify({
          OrderID,
          OrderNo,
          CustomerID,
          CustomerName,
          StringID,
          ColourID,
          ColourName,
          Lbs,
          Method,
          IsNew,
          Deposit,
          AmountPayable,
          PayStatus,
          ReceivedDate,
          DeliveryDate,
          Adjudicator,
          Cost,
          Remark,
          Enabled,
          ListOrderAddOn,
          ListCustomize,
        }),
        headers: { "content-type": "application/json" },
      }),
    }),
    createStringingRecord: builder.mutation({
      query: ({ ListOrderCreate }) => ({
        url: `Master/OrderCreate`,
        method: "POST",
        body: JSON.stringify({
          ListOrderCreate,
        }),
        headers: { "content-type": "application/json" },
      }),
    }),
    getStringQuantityByDateRange: builder.mutation({
      query: ({ StartDate, EndDate }) => ({
        url: `Master/GetStringQuantityByDateRange`,
        method: "POST",
        body: JSON.stringify({
          StartDate,
          EndDate,
        }),
        headers: { "content-type": "application/json" },
      }),
    }),
    getStringMonthlyQuantityByStringID: builder.mutation({
      query: ({ StringID }) => ({
        url: `Master/GetStringMonthlyQuantityByStringID`,
        method: "POST",
        body: JSON.stringify({
          StringID,
        }),
        headers: { "content-type": "application/json" },
      }),
    }),
  }),
});

export const {
  useGetOrderByDateMutation,
  useGetAdminOrderByDateMutation,
  useGetOrderOfTheDayMutation,
  useGetOrderDetailQuery,
  useUpdateOrderMutation,
  useCreateStringingRecordMutation,
  useGetStringQuantityByDateRangeMutation,
  useGetStringMonthlyQuantityByStringIDMutation,
} = stringingApi;
