import { styled, css } from "styled-components";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

const TooltipContainer = styled.div`
  background: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  border: 1px solid #1c1c1c;
`;

const TooltipList = styled.div``;

const TooltipItem = styled.div``;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <TooltipList>
          <TooltipItem>{`時間 : ${label}`}</TooltipItem>
        </TooltipList>
        <TooltipList>
          <TooltipItem>{`數量 : ${payload[0].value}`}</TooltipItem>
        </TooltipList>
      </TooltipContainer>
    );
  }

  return null;
};

const Chart = ({ chartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 15,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <XAxis
          dataKey="name"
          style={{
            fontSize: "14px",
          }}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Line
          dataKey="quantity"
          label={{ position: "top", fontSize: 12 }}
          stroke="#1c1c1c"
          type="monotone"
          fill="#1c1c1c"

          //   activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
